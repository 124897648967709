import { Text, Heading, Box } from "@chakra-ui/react";
import {
  render,
  StructuredTextGraphQlResponse,
} from "datocms-structured-text-to-plain-text";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import ArticleInfo from "src/components/ArticleInfo";
import { ArticleType } from "src/types/ArticleType";

import { ArticleCardProps } from "./ArticleCard";

const ellipseText = (text: string, { length }: { length: number }) => {
  let lastCharIndex = length - 1;

  while (text[lastCharIndex + 1] && ![" ", ","].includes(text[lastCharIndex])) {
    lastCharIndex += 1;
  }

  return lastCharIndex < text.length - 1
    ? text.slice(0, lastCharIndex) + "..."
    : text;
};

const CardMedaillon = ({ article, isFeatured }: ArticleCardProps) => {
  return (
    <>
      <ArticleInfo
        articleType={article.articleType as ArticleType}
        mb={1}
        publicationDate={article.publicationDate!}
      />

      <Heading as="h2" mb={5} noOfLines={3} size="md">
        {article.title}
      </Heading>

      <Text as="div" color="gray.700">
        {article.cover && (
          <Box
            alt=""
            as={GatsbyImage}
            float={{ base: "none", sm: "left" }}
            image={
              isFeatured
                ? article.cover.thumbnailMedium
                : article.cover.thumbnailSmall
            }
            mb={2}
            mr={{ sm: 4 }}
            width={{ base: "full", sm: "initial" }}
          />
        )}

        <Text>
          {ellipseText(
            article.excerpt ||
              render(article.body as unknown as StructuredTextGraphQlResponse)!,
            { length: isFeatured ? 710 : 285 }
          )}
        </Text>
      </Text>
    </>
  );
};

export default CardMedaillon;
