import { Text, Button, VStack } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import RcPagination from "rc-pagination";
import React from "react";

interface PaginationProps {
  currentPage: number;
  getPath: (page: number) => string;
  pageSize: number;
  totalItems: number;
}

const Pagination = ({
  currentPage,
  totalItems,
  pageSize,
  getPath,
}: PaginationProps) => {
  return (
    <VStack
      my={9}
      spacing={4}
      sx={{
        "ul.rc-pagination": {
          display: "flex",
          listStyleType: "none",
        },
      }}
    >
      <RcPagination
        current={currentPage}
        itemRender={(page, type) => {
          if (type === "next" || type === "prev") return null;

          return (
            <Button
              _active={{
                bg: "linkedin.500",
                color: "white",

                "&:hover": {
                  cursor: "unset",
                },
              }}
              as={GatsbyLink}
              isActive={currentPage === page}
              mx={1}
              title={`${page}`}
              to={getPath(page)}
            >
              {page}
            </Button>
          );
        }}
        onChange={() => {}}
        pageSize={pageSize}
        showPrevNextJumpers={false}
        total={totalItems}
      />

      <Text color="gray.500">
        {`${Math.ceil(totalItems / pageSize!)} pages, ${totalItems} articles`}
      </Text>
    </VStack>
  );
};

export default Pagination;
