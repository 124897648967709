import { SimpleGrid, SimpleGridProps } from "@chakra-ui/react";
import React from "react";

import ArticleCard from "./components/ArticleCard/ArticleCard";

interface ArticleGridProps extends SimpleGridProps {
  articles: readonly Gatsby.ArticleFragment[];
  featuredArticle?: Gatsby.ArticleFragment;
}

const ArticleGrid = ({
  articles,
  featuredArticle,
  ...props
}: ArticleGridProps) => {
  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} {...props}>
      {articles.map((article) => (
        <ArticleCard
          article={article}
          isFeatured={article.id === featuredArticle?.id}
          key={article.id}
        />
      ))}
    </SimpleGrid>
  );
};

export default ArticleGrid;
