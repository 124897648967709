import { BoxProps } from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";
import Card from "src/atoms/Card";
import getArticlePath from "src/lib/getArticlePath";

import CardBandeau from "./CardBandeau";
import CardMedaillon from "./CardMedaillon";

export interface ArticleCardProps extends BoxProps {
  article: Gatsby.ArticleFragment;
  isFeatured: boolean;
}

const ArticleCard = ({ article, isFeatured, ...props }: ArticleCardProps) => {
  const Content =
    article.coverFormat === "medaillon" ? CardMedaillon : CardBandeau;

  return (
    <Card
      as={Link}
      gridArea={isFeatured ? { base: "1 / 1", md: "1 / span 2" } : undefined}
      p={article.coverFormat === "bandeau" ? 0 : undefined}
      to={getArticlePath(article.slug!)}
      {...props}
    >
      <Content article={article} isFeatured={isFeatured} {...props} />
    </Card>
  );
};

export default ArticleCard;
