import { Text, Heading, SimpleGrid, Box } from "@chakra-ui/react";
import React from "react";
import ArticleInfo from "src/components/ArticleInfo";
import Picture from "src/components/Picture";
import getExcerpt from "src/lib/getExcerpt";
import { ArticleType } from "src/types/ArticleType";

import { ArticleCardProps } from "./ArticleCard";

const CardBandeau = ({ article, isFeatured }: ArticleCardProps) => (
  <SimpleGrid columns={{ base: 1, md: isFeatured ? 2 : 1 }} height="full">
    {article.cover && (
      <Picture
        height={isFeatured ? { base: 300, md: "full" } : undefined}
        image={
          isFeatured ? article.cover.cardCoverFeatured : article.cover.cardCover
        }
      />
    )}

    <Box p={{ base: 4, md: 6 }}>
      <ArticleInfo
        articleType={article.articleType as ArticleType}
        mb={1}
        publicationDate={article.publicationDate!}
      />

      <Heading as="h2" mb={5} noOfLines={3} size="md">
        {article.title}
      </Heading>

      <Text as="div" color="gray.700" noOfLines={isFeatured ? 12 : 5}>
        {article.excerpt || getExcerpt(article.body)}
      </Text>
    </Box>
  </SimpleGrid>
);

export default CardBandeau;
